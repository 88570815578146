import React from "react";
import { openModal } from "../stores/useModalStore";
import Modal from "./Modal";

interface Props {
  embed: string;
  title: string;
  subtitle: string;
  image?: string;
}

export default function VideoPlayer(props: Props) {
  const { embed, image, title, subtitle } = props;

  const handleClick = () => {
    if (!embed) {
      return;
    }
    openModal(
      <Modal className="video-embed">
        <div className="video" dangerouslySetInnerHTML={{ __html: embed }} />
      </Modal>
    );
  };

  return (
    <div className="video-thumbnail" onClick={handleClick}>
      <div className="image">
        <div className="button" />
        {image && <img src={image} />}
      </div>
      <h4>{title}</h4>
      {subtitle}
    </div>
  );
}
