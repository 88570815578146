import React, { useEffect } from "react";

import useModalStore, { closeModal } from "../stores/useModalStore";

export default function ModalContainer() {
  const component = useModalStore((state) => state.component);

  const handleClose = (evt: React.MouseEvent | KeyboardEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
    closeModal();
  };

  useEffect(() => {
    if (component) {
      document.body.classList.add("modal");
    } else {
      document.body.classList.remove("modal");
    }
  }, [component]);

  useEffect(() => {
    const handleKeypress = (evt: KeyboardEvent) => {
      if (component && (evt.key == "Escape" || evt.keyCode === 27)) {
        handleClose(evt);
      }
    };
    window.addEventListener("keypress", handleKeypress);
    return () => {
      window.removeEventListener("keypress", handleKeypress);
    };
  }, [component]);

  if (component) {
    return (
      <div className="modal-wrapper open">
        <div className="background" onClick={handleClose} />
        {component}
      </div>
    );
  } else {
    return <div className="modal-wrapper"></div>;
  }
}
