import React, { ReactNode } from "react";
import { closeModal } from "../stores/useModalStore";

interface Props {
  children: ReactNode;
  className?: string;
}

export default function Modal(props: Props) {
  const { children, className } = props;

  const handleClose = (evt: React.MouseEvent) => {
    evt.preventDefault();
    closeModal();
  };

  return (
    <div className={`modal ${className}`}>
      {children}
      <div className="close-button">
        <a href="#" onClick={handleClose}>
          Close video
        </a>
      </div>
    </div>
  );
}
